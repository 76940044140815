var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { maxWidth: "600" },
      model: {
        value: _vm.shown,
        callback: function ($$v) {
          _vm.shown = $$v
        },
        expression: "shown",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "console.platforms.mturk.top.credentials.manageDialog.title"
                  )
                ) +
                " "
            ),
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-overlay",
                {
                  attrs: { value: _vm.isLoading, absolute: "", color: "#bbb" },
                },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "grey", size: "40" },
                  }),
                ],
                1
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.signInFailed,
                      expression: "signInFailed",
                    },
                  ],
                  staticClass: "red--text",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "console.platforms.mturk.top.credentials.manageDialog.signInFailure"
                        )
                      )
                  ),
                  _c("br"),
                  _vm._v(
                    " (" +
                      _vm._s(
                        _vm.$t(
                          "console.platforms.mturk.top.credentials.manageDialog.signInFailureReason"
                        )
                      ) +
                      ": " +
                      _vm._s(_vm.signInFailureReason) +
                      ") "
                  ),
                ]
              ),
              _c("v-select", {
                ref: "select",
                attrs: {
                  dense: "",
                  outlined: "",
                  "return-object": "",
                  "hide-details": "",
                  label: _vm.$t(
                    "console.platforms.mturk.top.credentials.manageDialog.selectLabel"
                  ),
                  items: _vm.credentialItems,
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function ({ item }) {
                      return [
                        item.addNew
                          ? _c(
                              "div",
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-plus"),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "console.platforms.mturk.top.credentials.manageDialog.addNew"
                                      )
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            )
                          : _c("div", [
                              item.label !== ""
                                ? _c("div", {
                                    domProps: { innerHTML: _vm._s(item.label) },
                                  })
                                : _c("div", [
                                    _vm._v(
                                      "(" +
                                        _vm._s(
                                          _vm.$t(
                                            "console.platforms.mturk.top.credentials.manageDialog.unnamed"
                                          )
                                        ) +
                                        ")"
                                    ),
                                  ]),
                            ]),
                      ]
                    },
                  },
                  {
                    key: "item",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-list-item-content",
                          [
                            item.addNew
                              ? _c(
                                  "v-list-item-subtitle",
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("mdi-plus"),
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "console.platforms.mturk.top.credentials.manageDialog.addNew"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !item.addNew
                              ? _c("v-list-item-title", [
                                  item.label !== ""
                                    ? _c("div", [_vm._v(_vm._s(item.label))])
                                    : _c("div", [
                                        _vm._v(
                                          "(" +
                                            _vm._s(
                                              _vm.$t(
                                                "console.platforms.mturk.top.credentials.manageDialog.unnamed"
                                              )
                                            ) +
                                            ")"
                                        ),
                                      ]),
                                ])
                              : _vm._e(),
                            !item.addNew
                              ? _c("v-list-item-subtitle", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "console.platforms.mturk.top.credentials.manageDialog.selectSubtitle",
                                          {
                                            accessKeyId: item.access_key_id,
                                            datetime: _vm.dateFormat(
                                              item._created_at,
                                              "yyyy-mm-dd HH:mm:ss"
                                            ),
                                          }
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.credential,
                  callback: function ($$v) {
                    _vm.credential = $$v
                  },
                  expression: "credential",
                },
              }),
            ],
            1
          ),
          _c("v-simple-table", { attrs: { dense: "" } }, [
            _c("tbody", [
              _c("tr", [
                _c("th", { attrs: { width: "30%" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "console.platforms.mturk.top.credentials.manageDialog.parameters.label"
                      )
                    )
                  ),
                ]),
                _c(
                  "td",
                  [
                    _c("v-text-field", {
                      attrs: {
                        dense: "",
                        "hide-details": "",
                        error: _vm.signInFailed,
                      },
                      on: {
                        blur: _vm.renameCredentials,
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.renameCredentials.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.credential.label,
                        callback: function ($$v) {
                          _vm.$set(_vm.credential, "label", $$v)
                        },
                        expression: "credential.label",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("tr", [
                _c("th", [_vm._v("Access Key ID")]),
                _c(
                  "td",
                  [
                    _c("v-text-field", {
                      attrs: {
                        dense: "",
                        "hide-details": "",
                        disabled: !_vm.credential.addNew,
                        error: _vm.signInFailed,
                      },
                      model: {
                        value: _vm.credential.access_key_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.credential, "access_key_id", $$v)
                        },
                        expression: "credential.access_key_id",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("tr", [
                _c(
                  "th",
                  [
                    _vm._v(" Secret Access Key "),
                    _c("v-icon", {
                      staticClass: "ml-2",
                      attrs: { small: "" },
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.secretAccessKeyShown ? "mdi-eye-off" : "mdi-eye"
                        ),
                      },
                      on: {
                        click: function ($event) {
                          _vm.secretAccessKeyShown = !_vm.secretAccessKeyShown
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("v-text-field", {
                      attrs: {
                        dense: "",
                        "hide-details": "",
                        disabled: !_vm.credential.addNew,
                        error: _vm.signInFailed,
                        type: _vm.secretAccessKeyShown ? "text" : "password",
                      },
                      model: {
                        value: _vm.credential.secret_access_key,
                        callback: function ($$v) {
                          _vm.$set(_vm.credential, "secret_access_key", $$v)
                        },
                        expression: "credential.secret_access_key",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("v-spacer"),
          !_vm.credential.addNew
            ? _c(
                "v-btn",
                {
                  staticClass: "ma-2",
                  attrs: { small: "", dense: "", outlined: "", color: "error" },
                  on: {
                    click: function ($event) {
                      return _vm.$refs.deleteCredentialsDialogAlert.show()
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v("mdi-trash-can-outline"),
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "console.platforms.mturk.top.credentials.manageDialog.deleteButton"
                        )
                      ) +
                      " "
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("tutti-dialog-actions", {
            attrs: {
              actions: [
                {
                  label: _vm.$t(
                    "console.platforms.mturk.top.credentials.manageDialog.actions.cancel"
                  ),
                  color: "grey darken-1",
                  text: true,
                  callback: _vm.hide,
                },
                {
                  label: _vm.$t(
                    "console.platforms.mturk.top.credentials.manageDialog.actions.confirm"
                  ),
                  color: "indigo darken-1",
                  dark: !_vm.credentialInfoIsIncomplete,
                  disabled: _vm.credentialInfoIsIncomplete,
                  callback: _vm.credential.addNew
                    ? _vm.addCredentials
                    : () => _vm.setActiveCredentials(_vm.credential.id),
                },
              ],
            },
          }),
        ],
        1
      ),
      _c("tutti-dialog-alert", {
        ref: "deleteCredentialsDialogAlert",
        attrs: {
          title: _vm.$t(
            "console.platforms.mturk.top.credentials.manageDialog.deleteDialog.title"
          ),
          body: _vm.$t(
            "console.platforms.mturk.top.credentials.manageDialog.deleteDialog.text",
            { label: _vm.credential.label }
          ),
          "max-width": 500,
        },
        on: { confirm: _vm.deleteCredentials },
      }),
      _c("tutti-snackbar", { ref: "snackbar" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }