<template>
    <v-row>
        <v-col lg="3" sm="4" cols="12" v-for="card in cards" :key="card.to">
            <v-card tile hover :ripple="false" class="pa-2" height="200" :to="card.to">
                <div class="pt-4 pl-4"><v-icon large :color="card.color">{{ card.icon }}</v-icon></div>
                <div class="pl-4 pt-2 font-weight-medium">{{ card.title }}</div>
                <v-card-text class="pt-2">{{ card.text }}</v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    data() {
        return {
            cards: [
                {
                    icon: 'mdi-file-document-multiple-outline',
                    color: 'indigo',
                    title: this.$t('console.platforms.mturk.top.services.hits.title'),
                    text: this.$t('console.platforms.mturk.top.services.hits.text'),
                    to: "hit"
                },
                {
                    icon: 'mdi-account-group',
                    color: 'green',
                    title: this.$t('console.platforms.mturk.top.services.workers.title'),
                    text: this.$t('console.platforms.mturk.top.services.workers.text'),
                    to: "worker"
                },
                {
                    icon: 'mdi-certificate',
                    color: 'amber',
                    title: this.$t('console.platforms.mturk.top.services.qualifications.title'),
                    text: this.$t('console.platforms.mturk.top.services.qualifications.text'),
                    to: "qual"
                },
                {
                    icon: 'mdi-comment-check-outline',
                    color: 'pink',
                    title: this.$t('console.platforms.mturk.top.services.assignments.title'),
                    text: this.$t('console.platforms.mturk.top.services.assignments.text'),
                    to: "assignment"
                },
            ]
        }
    }
}
</script>
