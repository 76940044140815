var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    _vm._l(_vm.cards, function (card) {
      return _c(
        "v-col",
        { key: card.to, attrs: { lg: "3", sm: "4", cols: "12" } },
        [
          _c(
            "v-card",
            {
              staticClass: "pa-2",
              attrs: {
                tile: "",
                hover: "",
                ripple: false,
                height: "200",
                to: card.to,
              },
            },
            [
              _c(
                "div",
                { staticClass: "pt-4 pl-4" },
                [
                  _c("v-icon", { attrs: { large: "", color: card.color } }, [
                    _vm._v(_vm._s(card.icon)),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "pl-4 pt-2 font-weight-medium" }, [
                _vm._v(_vm._s(card.title)),
              ]),
              _c("v-card-text", { staticClass: "pt-2" }, [
                _vm._v(_vm._s(card.text)),
              ]),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }