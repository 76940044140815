<template>
    <v-dialog v-model="shown" maxWidth="600">
        <v-card>
            <v-card-title>
                {{ $t('console.platforms.mturk.top.credentials.manageDialog.title') }}
            </v-card-title>
            <v-card-text>
                <v-overlay :value="isLoading" absolute color="#bbb">
                    <v-progress-circular indeterminate color="grey" size="40" />
                </v-overlay>
                <p v-show="signInFailed" class="red--text">
                    {{ $t('console.platforms.mturk.top.credentials.manageDialog.signInFailure') }}<br>
                    ({{ $t('console.platforms.mturk.top.credentials.manageDialog.signInFailureReason') }}: {{ signInFailureReason }})
                </p>
                <v-select
                    dense
                    outlined
                    return-object
                    hide-details
                    :label="$t('console.platforms.mturk.top.credentials.manageDialog.selectLabel')"
                    ref="select"
                    v-model="credential"
                    :items="credentialItems">
                    <template v-slot:selection="{ item }">
                        <div v-if="item.addNew">
                            <v-icon small>mdi-plus</v-icon>
                            {{ $t('console.platforms.mturk.top.credentials.manageDialog.addNew') }}
                        </div>
                        <div v-else>
                            <div v-if="item.label!==''" v-html="item.label" />
                            <div v-else>({{ $t('console.platforms.mturk.top.credentials.manageDialog.unnamed') }})</div>
                        </div>
                    </template>
                    <template v-slot:item="{ item }">
                        <v-list-item-content>
                            <v-list-item-subtitle v-if="item.addNew">
                                <v-icon small>mdi-plus</v-icon>
                                {{ $t('console.platforms.mturk.top.credentials.manageDialog.addNew') }}
                            </v-list-item-subtitle>
                            <v-list-item-title v-if="!item.addNew">
                                <div v-if="item.label!==''">{{ item.label }}</div>
                                <div v-else>({{ $t('console.platforms.mturk.top.credentials.manageDialog.unnamed') }})</div>
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="!item.addNew">
                                {{ $t('console.platforms.mturk.top.credentials.manageDialog.selectSubtitle', { accessKeyId: item.access_key_id, datetime: dateFormat(item._created_at, 'yyyy-mm-dd HH:mm:ss') }) }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-select>
            </v-card-text>
            <v-simple-table dense>
                <tbody>
                    <tr>
                        <th width="30%">{{ $t('console.platforms.mturk.top.credentials.manageDialog.parameters.label') }}</th>
                        <td><v-text-field dense hide-details :error="signInFailed" v-model="credential.label" @blur="renameCredentials" @keyup.enter="renameCredentials" /></td>
                    </tr>
                    <tr>
                        <th>Access Key ID</th>
                        <td><v-text-field dense hide-details :disabled="!credential.addNew" :error="signInFailed" v-model="credential.access_key_id" /></td>
                    </tr>
                    <tr>
                        <th>
                            Secret Access Key
                            <v-icon small class="ml-2" @click="secretAccessKeyShown = !secretAccessKeyShown" v-html="secretAccessKeyShown ? 'mdi-eye-off' : 'mdi-eye'" />
                        </th>
                        <td><v-text-field dense hide-details :disabled="!credential.addNew" :error="signInFailed" v-model="credential.secret_access_key" :type="secretAccessKeyShown ? 'text' : 'password'" /></td>
                    </tr>
                </tbody>
            </v-simple-table>
            <v-spacer />
            <v-btn small dense outlined v-if="!credential.addNew" color="error" class="ma-2" @click="$refs.deleteCredentialsDialogAlert.show()">
                <v-icon small>mdi-trash-can-outline</v-icon>
                {{ $t('console.platforms.mturk.top.credentials.manageDialog.deleteButton') }}
            </v-btn>
            <tutti-dialog-actions
                :actions="[
                        {
                            label: $t('console.platforms.mturk.top.credentials.manageDialog.actions.cancel'),
                            color: 'grey darken-1',
                            text: true,
                            callback: hide,
                        },
                        {
                            label: $t('console.platforms.mturk.top.credentials.manageDialog.actions.confirm'),
                            color: 'indigo darken-1',
                            dark: !credentialInfoIsIncomplete,
                            disabled: credentialInfoIsIncomplete,
                            callback: credential.addNew ? addCredentials : () => (setActiveCredentials(credential.id)),
                        },
                    ]"
            />
        </v-card>
        <tutti-dialog-alert
            ref="deleteCredentialsDialogAlert"
            @confirm="deleteCredentials"
            :title="$t('console.platforms.mturk.top.credentials.manageDialog.deleteDialog.title')"
            :body="$t('console.platforms.mturk.top.credentials.manageDialog.deleteDialog.text', { label: credential.label })"
            :max-width="500"
        />
        <tutti-snackbar ref="snackbar" />
    </v-dialog>
</template>
<script>
import tuttiDialogMixIn from '@/mixins/ui/tuttiDialog'
import TuttiDialogActions from '@/components/ui/TuttiDialogActions'
import TuttiDialogAlert from '@/components/ui/TuttiDialogAlert'
import TuttiSnackbar from '@/components/ui/TuttiSnackbar'
import dateFormat from 'dateformat'

const emptyCredential = {
        label: '',
        access_key_id: '',
        secret_access_key: ''
    };

export default {
    components: {
        TuttiDialogActions,
        TuttiDialogAlert,
        TuttiSnackbar
    },
    mixins: [ tuttiDialogMixIn ],
    props: ['client'],
    data: () => ({
        dateFormat,
        credential: { ...emptyCredential },
        credentialItems: [],
        secretAccessKeyShown: false,
        isLoading: true,
        signInFailed: false,
        signInFailureReason: '',
    }),
    computed: {
        credentialInfoIsIncomplete() {
            return !this.credential.access_key_id || !this.credential.secret_access_key;
        }
    },
    methods: {
        async renameCredentials(event) {
            if(this.credential.addNew) return;
            try {
                await this.client.mturk.renameCredentials({
                    credentials_id: this.credential.id,
                    label: this.credential.label
                });
                this.$refs.snackbar.show('success', this.$t('console.platforms.mturk.top.credentials.manageDialog.snackbar.renameSuccess'));
                event.target.blur();
            } catch {
                this.$refs.snackbar.show('error', this.$t('console.platforms.mturk.top.credentials.manageDialog.snackbar.renameFailure'));
                this.credential = await this.client.mturk.getCredentials({ credentials_id: this.credential.id });
            }
        },
        async listCredentials() {
            const credentialItems = await this.client.mturk.listCredentials();
            return credentialItems.map((item) => ({ ...item, _created_at: item['_created_at']*1000, value: item['access_key_id'], addNew: false }));
        },
        async addCredentials() {
            this.isLoading = true;
            try {
                const credentialsId = await this.client.mturk.addCredentials({
                        access_key_id: this.credential.access_key_id,
                        secret_access_key: this.credential.secret_access_key,
                        label: this.credential.label
                    });
                await this.setActiveCredentials(credentialsId);
            } catch (e) {
                if(e.code==this.client.ERROR.MARKETPLACE_MTURK.INVALID_ACCOUNT){
                    this.isLoading = false;
                    this.signInFailed = true;
                    this.signInFailureReason = e.details.details;
                }
            }
        },
        async setActiveCredentials(credentialsId) {
            try {
                this.isLoading = true;
                await this.client.mturk.setActiveCredentials({ credentials_id: credentialsId });
                this.isLoading = false;
                this.hide();
            } catch(e) {
                this.isLoading = false;
                if(e.code==this.client.ERROR.MARKETPLACE_MTURK.INVALID_ACCOUNT){
                    this.signInFailed = true;
                    this.signInFailureReason = e.details.details;
                }
            }
        },
        async deleteCredentials() {
            await this.client.mturk.deleteCredentials({ credentials_id: this.credential.id });
            this.hide();
        },
    },
    watch: {
        shown() {
            if(!this.shown) return;
            this.client.invokeOnOpen(async () => {
                this.credentialItems = [
                    { value: '', label: '', access_key_id: '', secret_access_key: '', addNew: true },
                    ...await this.listCredentials()
                ];
                let credentials = await this.client.mturk.getActiveCredentials();
                if(credentials['access_key_id']) {
                    const idx = this.credentialItems.findIndex((item) => (item.value==credentials['access_key_id']));
                    if(idx > -1) {
                        this.credential = this.credentialItems[idx];
                    } else {
                        this.credential = this.credentialItems[0];
                    }
                } else {
                    this.credential = this.credentialItems[0];
                }
                this.isLoading = false;
            });
        }
    }
}
</script>
